import React from 'react'

const GeneralLedger = () => {
  return (
    <div>
      general ledger
    </div>
  )
}

export default GeneralLedger
